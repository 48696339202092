/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "influxapi",
            "endpoint": "https://1tkd6h9z6g.execute-api.ap-southeast-2.amazonaws.com/ci",
            "region": "ap-southeast-2"
        },
        {
            "name": "integration",
            "endpoint": "https://04bo1xk5la.execute-api.ap-southeast-2.amazonaws.com/ci",
            "region": "ap-southeast-2"
        },
        {
            "name": "webhook",
            "endpoint": "https://nxoetpeog5.execute-api.ap-southeast-2.amazonaws.com/ci",
            "region": "ap-southeast-2"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-2:407e77a0-ca95-4fa0-98f8-47b1c5e773c3",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_6VOWBhyEx",
    "aws_user_pools_web_client_id": "3krlcotdu8bvju1gtghu9g9e3h",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "ap-southeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "commissioningCompletedMessage-ci",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "controllableLoadConsumption-ci",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "discoreco-ci",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "discorecoTasks-ci",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "gmexport-ci",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "group-ci",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "groupusers-ci",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "loadcontrol-ci",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "loadcontrolhistory-ci",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterDataExportHistory-ci",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterEvents-ci",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterStatusRefreshHistory-ci",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "meterstatus-ci",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "onsitemetercomm-ci",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "org-ci",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "policy-ci",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "provisioningCompletedMessage-ci",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "service-ci",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "tasks-ci",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "touLatestReportMessage-ci",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "user-ci",
            "region": "ap-southeast-2"
        }
    ]
};


export default awsmobile;
